export default function drawOutline(data, cut) {
    return new Promise((resolve) => {
        const { artworkUrl512, kind } = data;
        const canvas = document.createElement('canvas');
        canvas.width = 512;
        canvas.height = 512;
        const ctx = canvas.getContext('2d');
        const appIcon = new Image();
        appIcon.setAttribute('crossOrigin', 'anonymous');
        appIcon.src = artworkUrl512;
        appIcon.onload = function () {
            ctx.drawImage(appIcon, 0, 0);
            ctx.globalCompositeOperation = 'destination-in';
            if (kind.startsWith('software')) {
                if (cut === '1') {
                    const outline = new Path2D('M512,351.84c0,6.12,0,12.23-.04,18.34-.03,5.15-.09,10.31-.22,15.45-.14,11.27-1.13,22.51-2.96,33.63-1.9,11.13-5.45,21.91-10.54,31.98-10.31,20.24-26.76,36.7-47,47.01-10.07,5.08-20.84,8.63-31.96,10.53-11.13,1.84-22.37,2.83-33.64,2.96-5.15.13-10.3.21-15.45.23-6.12.03-12.23.03-18.34.03h-191.69c-6.11,0-12.22,0-18.34-.03-5.15-.02-10.3-.09-15.45-.22-11.27-.14-22.51-1.14-33.64-2.97-11.12-1.89-21.89-5.45-31.96-10.53-20.24-10.31-36.69-26.76-47-46.99-5.09-10.08-8.64-20.86-10.54-31.99-1.83-11.11-2.82-22.35-2.96-33.62-.13-5.15-.2-10.31-.22-15.46-.04-6.12-.04-12.22-.04-18.34v-191.67c0-6.12,0-12.24.04-18.37.02-5.14.09-10.3.22-15.44.14-11.26,1.13-22.5,2.96-33.62,1.9-11.13,5.45-21.91,10.54-31.99,10.31-20.24,26.76-36.7,47-47.01,10.07-5.08,20.83-8.63,31.94-10.53,11.13-1.83,22.37-2.82,33.64-2.96,5.15-.13,10.31-.2,15.45-.22,6.12-.03,12.24-.03,18.34-.03h191.69c6.12,0,12.24,0,18.35.03,5.15.02,10.3.09,15.45.22,11.26.14,22.51,1.14,33.63,2.96,11.13,1.9,21.89,5.45,31.97,10.53,20.24,10.31,36.7,26.76,47.01,47.01,5.08,10.08,8.63,20.86,10.53,31.98,1.83,11.12,2.82,22.36,2.96,33.63.13,5.15.2,10.31.22,15.45.04,6.12.04,12.23.04,18.34v191.69Z')
                    ctx.fill(outline);
                } else {
                    const outline = new Path2D('M512, M512')
                    ctx.fill(outline);
                }
            }
            resolve(canvas.toDataURL());
        };
    });
}
